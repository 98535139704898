<template>
   <div class="container">
      <div class="referral-main">
         <div class="row justify-content-md-center">
            <div class="col-12 col-lg-8 mt-3 past-earnings-log mb-5" v-if="referralData != []">

               <h4 class="text-center mb-4">Step 1: Copy Earnings Report</h4>

               <div>
               <label>Up to Year: <input size=7 v-model="reportYear"></label>&nbsp;&nbsp;&nbsp;<label>Month: <input size=7  v-model="reportMonth"></label> 
               <button v-if="isReportDirty" @click="refreshReport">Refresh</button>  
               <button v-else @click="copyReport">Copy</button><br>&nbsp;
               <p class="text-danger">{{copyMessage}}<br/></p>
               </div>
               <table class="admin-table">
                  <tr>
                     <th>Account#</th>
                     <th>Username</th>
                     <th>UsdcAddress</th>
                     <th>Year</th>
                     <th>Month</th>
                     <th>Revenue (USD)</th>
                     <th>Commission (USD)</th>
                     <th>F/E</th>
                     <th style="color: #ccc">Transactions</th>
                     <th style="color: #ccc">Memo</th>
                  </tr>
                  <tr v-for="value in calculatedPayments" v-bind:key="value['username']">
                     <td>
                        {{value["account_number_int"]}}
                     </td>
                     <td>
                        {{value["username"]}}
                     </td>
                     <td>
                        {{value["usdc_address"]}}
                     </td>
                     <td>
                        {{value["year"]}}
                     </td>
                     <td>
                        {{value["month"]}}
                     </td>
                     <td>
                        {{value["amount"] / 0.3}}
                     </td>
                     <td>
                        {{value["amount"]}}
                     </td>
                     <td>
                        0.3
                     </td>
                     <td>
                     </td>
                     <td>
                     </td>
                  </tr>
               </table>

               <div class="mt-3 mb-3">
                  <a target=_blank href="https://docs.google.com/spreadsheets/d/1LcS4ZdfJIWrlI9Gy67-R4gP64qA8rV3L2SH-VN41bM8/edit?usp=sharing">Admin Worksheet Link</a>
               </div>

               <hr/>

               <h4 class="text-center mb-4">Step 2: Upload and Update Payment Information</h4>
               <div>
                  <input type="file" @change="uploadFile" ref="csvFile">
                  <button @click="submitFile">Upload TSV (not CSV) and Process</button>
               </div>
               <div>
                  <p style="color: red" v-if="uploadError">{{uploadError}}</p>
                  <p style="color: green" v-if="isUploadSuccess">Successfully processed. Refresh page to see changes.</p>
               </div>

               <hr/>

               <h4 class="text-center mb-4">Last Payment Batch: {{displayedReportMonth}}/{{displayedReportYear}}</h4>
               <table class="admin-table">
                  <tr>
                     <th>Username</th>
                     <th>UsdcAddress</th>
                     <th>Account#</th>
                     <th>Year</th>
                     <th>Month</th>
                     <th>Amount</th>
                     <th>Transactions</th>
                     <th>Memo</th>
                  </tr>
                  <tr v-for="value in recordedPayments" v-bind:key="value['username']">
                     <td>
                        {{value["username"]}}
                     </td>
                     <td>
                        {{value["usdc_address"]}}
                     </td>
                     <td>
                        {{value["account_number_int"]}}
                     </td>
                     <td>
                        {{value["year"]}}
                     </td>
                     <td>
                        {{value["month"]}}
                     </td>
                     <td>
                        {{Number(value["dollars_times_100"]) / 100.0}}
                     </td>
                     <td>
                        {{value["transactions"]}}
                     </td>
                     <td>
                        {{value["memo"]}}
                     </td>
                  </tr>
               </table>

            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
   name: "Referral",
   data() {
      return {
         csvFile: null,
         referralData: [],
         confirmTexts: {},
         calculatedPayments: [],
         recordedPayments: [],
         isUploadSuccess: false,
         uploadError: "",
         copyMessage: "",
         reportYear: 0,
         reportMonth: 0,
         displayedReportYear: 0,
         displayedReportMonth: 0,
      }
   },
   computed: {
      isReportDirty() {
         return this.displayedReportYear != this.reportYear || this.displayedReportMonth != this.reportMonth;
      }
   },
   mounted() {
      let today = new Date();
      this.reportYear = today.getFullYear();
      this.reportMonth = today.getMonth() + 1;

      this.reportMonth -= 2;
      if (this.reportMonth < 1)
      {
         this.reportMonth += 12;
         this.reportYear -= 1;
      }

      this.refreshReport();
   },
   methods: {
      refreshReport() {
         this.copyMessage = "";

         this.axios
            .post("/api/referral-admin", 
            {
               year: this.reportYear,
               month: this.reportMonth,
            }, 
            { withCredentials: true })
            .then((response) => {
               this.calculatedPayments = response.data.calculated_payments;
               this.recordedPayments = response.data.recorded_payments;
               console.log(this.referralData);

               this.displayedReportYear = this.reportYear;
               this.displayedReportMonth = this.reportMonth;
            })
            .catch((error) => console.error(error));

      },
      copyReport() {
         let text = "";
         let sep = "";
         for (let i = 0; i < this.calculatedPayments.length; ++i) {
            text += sep;
            let pmt = this.calculatedPayments[i];
            text += `${pmt.account_number_int}\t${pmt.username}\t${pmt.usdc_address}\t${pmt.year}\t${pmt.month}\t${pmt.amount/0.3}\t${pmt.amount}\t0.3\t\t`;
            sep = "\n";
         }
         navigator.clipboard.writeText(text);
         this.copyMessage = "NOTE: Paste these new rows AT THE BOTTOM of the referral management spreadsheet, please be careful NOT TO OVERWRITE existing entries.";
      },
      uploadFile() {
         this.csvFile = this.$refs.csvFile.files[0];
      },
      submitFile() {
         const formData = new FormData();
         formData.append('file', this.csvFile);
         const headers = { 'Content-Type': 'multipart/form-data', withCredentials: true };

         this.isUploadSuccess = false;
         this.axios
            .post("/api/referral-admin-record-payments", 
               formData,
               headers)
            .then((response) => {
               if (response.data && response.data.status) {
                  if (response.data.status === 'success') {
                     this.uploadError = "";
                     this.isUploadSuccess = true;
                  }
                  else {
                     this.uploadError = response.data.status;
                  }
               }
               else {
                  this.uploadError = "Error occurred processing upload.";
               }
               //window.location.assign(window.location.href);
            })
            .catch((error) => console.error(error));

      },
      recordPayment() {
      },
   }
}
</script>